<template>
  <div class='container'>
    <h5>
        <img @click="$router.replace('/setting')" src="@assets/img/profile/icon-back.png"/>实名认证
    </h5>
    <div class="content">
        <div class="tip" v-html="auth.content"></div>
        <div class="form">
            <h6>{{auth.title}}</h6>
            <div class="form-item">
                <div class="form-item-title">真实姓名</div>
                <div class="form-item-content">
                    <input type="text" v-model.trim="realname" placeholder="请输入您的姓名" autocomplete="off"/>
                    <img src="@assets/img/profile/icon-clear.png" @click="realname=''" v-show="realname!=''"/>
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-title">身份证号</div>
                <div class="form-item-content">
                    <input type="text" v-model.trim="idCard" placeholder="请输入您的身份证号" autocomplete="off"/>
                    <img src="@assets/img/profile/icon-clear.png" @click="idCard=''" v-show="idCard!=''"/>
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-title">手机号码</div>
                <div class="form-item-content">
                    <input type="tel" v-model.trim="phone" placeholder="请输入手机号" autocomplete="off"/>
                    <img src="@assets/img/profile/icon-clear.png" @click="phone=''" v-show="phone!=''"/>
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-title">验证码</div>
                <div class="form-item-content">
                    <input maxlength="4" type="text" v-model.trim="code" style="width:105px;padding:0 0 0 9px" placeholder="请输入验证码" autocomplete="off"/>
                    <span @click="sendCode">{{sendText}}</span>
                </div>
            </div>
            <div class="btn" @click="submit">提交</div>
        </div>
    </div>
  </div>
</template>

<script>
import {getCode} from "@assets/api/home/home";
import {verified, verifiedHelp} from "@assets/api/user/user";

export default {
  name:'',
  components: {},

  data () {
    return {
        reg:/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
        realname:'',
        idCard:'',
        phone:'',
        code:'',
        auth:{},
        timer:null,
        sendText:'发送验证码',
        flag:false,
    };
  },

  computed: {},

  created(){
    this.getHtml()
  },

  methods: {
    getHtml(){
      verifiedHelp().then(res=>{
        console.log('res',res)
        this.auth = res.data
      })
    },
    //提交
    submit(){
        if(this.realname==''){
            return this.$Message.error('请输入您的姓名')
        }else if(this.idCard==''){
            return this.$Message.error('请输入您的身份证号')
        }else if(this.phone==''||!this.reg.test(this.phone)){
            return this.$Message.error('请输入手机号')
        }else if(this.code==''){
            return this.$Message.error('请输入验证码')
        }
        let params = {
          real_name:this.realname,
          id_card:this.idCard,
          phone:this.phone,
          msg_code:this.code
        }
        verified(params).then(res=>{
          if(res.code == 1){
            this.$Message.success(`${res.msg}`)
            this.$router.go(-1)
          }else{
            this.$Message.error(`${res.msg}`)
          }
        })
    },
    //发送验证码
    sendCode(){
      if(this.flag){
        return false;
      }

      if(this.realname==''){
        return this.$Message.error('请输入您的姓名')
      }else if(this.idCard==''){
        return this.$Message.error('请输入您的身份证号')
      }else if(this.phone==''||!this.reg.test(this.phone)){
        return this.$Message.error('请输入手机号')
      }

      let time=10;
      this.timer=setInterval(()=>{
        time--;
        if(time==0){
          clearInterval(this.timer);
          this.sendText='发送验证码';
          time=10;
          this.flag=false;
        }else{
          this.sendText=time+'s'
        }
      },1000)
      let params = {
        phone:this.phone
      }
      getCode(params).then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          // this.code = res.msg.substr(res.msg.length - 4)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    }
  },

}

</script>
<style lang='less' scoped>
@import '@assets/css/profile/account/realNameAuth.less';
</style>